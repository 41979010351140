import { render, staticRenderFns } from "./LastMessages.vue?vue&type=template&id=6e18834a&scoped=true&"
import script from "./LastMessages.vue?vue&type=script&lang=js&"
export * from "./LastMessages.vue?vue&type=script&lang=js&"
import style0 from "./LastMessages.vue?vue&type=style&index=0&id=6e18834a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e18834a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})


/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
